import React from "react"
import Navbar from "../../components/navbar"
import Header from "../../components/header"
import BackgroundShape from "../../components/backgroundShape"
import Container from "../../components/container"
import Layout from "../../components/layout"
import Head from "../../components/head"

const link = "https://assets.onport.com/assets/files/onport-mach.pdf"

const downloadPdf = (link) => {
   if (typeof window !== `undefined`) {
     let newTab = window.open();
     newTab.location.href = link;
    }
}

const download = () => {

   return (
    <Layout navbar="transparent">
                <Head title='Headless | Download' description=""/>
                    <BackgroundShape style={{shape: "clip-fixed-small", color: "bg-download-sm", particles: false }}/>
                    <Container style={{classes: "container" }}>
                    <Header content={{title: `Headless - Download`, text: ""}} style={{color: "text-center text-light"}}/>
                    </Container>
                        <div className="text-center my-4 py-2">
                            <p className="">If the download hasn't started yet click <a href={link} alt="headless" target="_blank">here</a> 
                            </p>
                        </div>
            {downloadPdf(link)}
            </Layout>
    )
}


export default download
